import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUpRightFromSquare, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '../../components/grid';
import { Button, Form, Card, Row, Col } from 'react-bootstrap';
import { ObtenerExpedientes, ObtenerClubDeViaje, ObtenerPaqueteDeViaje } from '../../servicios/ServicioBuscadorExpedientes';
import DatePicker from 'react-datepicker'; // Importa DatePicker
import 'react-datepicker/dist/react-datepicker.css'; // Importa el CSS de DatePicker
import es from 'date-fns/locale/es'; // Importa el local de español
import ModalExportarEstadoDeCuentaPDF from "../reportes/ModalExportarEstadoDeCuentaPDF";
import { ObtenerListaEstadosDeCuenta } from "../../servicios/ServicioEstadoDeCuenta";
import { jsPDF } from "jspdf";
import encabezado from '../../images/encabezado-instinto-viajero.png';


const EstadosDeCuenta = () => {
    const [proceso, setProceso] = useState(1);
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(1);
    const [pendiente, setPendiente] = useState(false);
    const [listaDeRegistros, setListaDeRegistros] = useState([]);
    const [identificacion, setIdentificacion] = useState("");
    const [nombre, setNombre] = useState("");
    const [primerApellido, setprimerApellido] = useState("");
    const [segundoApellido, setsegundoApellido] = useState("");
    const [numContrato, setNumContrato] = useState(null);
    const [destino, setDestino] = useState(null);
    const [mensajeRespuesta, setMensajeRespuesta] = useState({});
    const [bloquearBoton, setBloquearBoton] = useState(true);
    const [filaSeleccionada, setFilaSeleccionada] = useState({});
    const [mostrarBusqueda, setMostrarBusqueda] = useState(true);
    const [data, setData] = useState({});

    const [showModal, setShowModal] = useState(false);
    const [selectedDato, setSelectedDato] = useState(null);
    const [idUsuario, setIdUsuario] = useState(null);
    const [mostrarGrid, setMostrarGrid] = useState(false);

    const handleModalClose = () => {
        setShowModal(false);
        setSelectedDato(null);
    };

    const handleExportPDF = async (listaDeRegistros, datos) => {
        try {
            if (listaDeRegistros != '') {
                await generatePDF(listaDeRegistros, datos);
                handleModalClose();
            }
        } catch (error) {
            console.error('Error al generar el PDF:', error);
        }
    };

    const generatePDF = async (listaDeRegistros, datos) => {
        const doc = new jsPDF();
        const imgBase64 = await convertirImagenABase64(encabezado);
        const imgWidth = doc.internal.pageSize.width - 20;
        const imgHeight = 30;
        const imgX = 10;
        const imgY = 10;

        doc.addImage(imgBase64, 'PNG', imgX, imgY, imgWidth, imgHeight);
        doc.setFontSize(18);
        doc.setTextColor(216, 92, 44);
        doc.text('Estado de Cuenta', 80, 45);

        doc.setFontSize(16);
        doc.setTextColor(0);
        const textoAdicional = opcionSeleccionada === 1 ? 'Club de viaje' : 'Paquete de viaje';
        doc.text(textoAdicional, 90, 55);

        doc.setTextColor(0);
        doc.setFontSize(14);
        doc.text('Datos del Usuario', 20, 62);
        doc.text('SOCIO: ' + datos.nombre + ' ' + datos.primerApellido + ' ' + datos.segundoApellido, 20, 69);
        doc.text('CÉDULA: ' + datos.identificacion, 20, 76);
        doc.text('ID: ' + (datos.numContrato ?? datos.destino), 20, 83);
        doc.setFontSize(12);

        const columns = ['DESCRIPCIÓN', 'FECHA', 'MONTO'];
        let data = [];

        listaDeRegistros.forEach((row) => {
            const rowData = [
                row.cuota ? row.cuota.toString() : '',
                row.fechaPago ? row.fechaPago.toString() : '',
                row.monPagar ? row.monPagar.toString() : ''
            ];
            data.push(rowData);
        });

        let startY = 87;
        const margin = 20;
        const columnWidth = (doc.internal.pageSize.width - 2 * margin) / columns.length;

        doc.setFont('helvetica', 'normal');
        doc.setDrawColor(0);
        doc.setTextColor(0);

        columns.forEach((column, index) => {
            doc.setFillColor('#d85c2c');
            doc.rect(margin + index * columnWidth, startY, columnWidth, 10, 'FD');
            doc.text(column, margin + index * columnWidth + columnWidth / 2, startY + 8, { align: 'center' });
        });

        startY += 10;

        const pageHeight = doc.internal.pageSize.height;
        const rowHeight = 10;
        const rowsPerPage = Math.floor((pageHeight - startY - margin) / rowHeight);

        doc.setTextColor(0);
        data.forEach((rowData, rowIndex) => {
            if (startY > pageHeight - margin) {
                doc.addPage();
                startY = margin;

                columns.forEach((column, index) => {
                    doc.setFillColor('#d85c2c');
                    doc.rect(margin + index * columnWidth, startY, columnWidth, 10, 'FD');
                    doc.text(column, margin + index * columnWidth + columnWidth / 2, startY + 8, { align: 'center' });
                });

                startY += 10;
            }

            const fillColor = rowIndex % 2 === 0 ? [200, 200, 200] : [255, 255, 255];
            rowData.forEach((cell, index) => {
                doc.setFillColor(fillColor[0], fillColor[1], fillColor[2]);
                doc.rect(margin + index * columnWidth, startY, columnWidth, rowHeight, 'FD');
                doc.text(cell, margin + index * columnWidth + columnWidth / 2, startY + 8, { align: 'center' });
            });
            startY += rowHeight;
        });

        doc.save(`estado_de_cuenta_${datos.nombre + ' ' + datos.primerApellido + ' ' + datos.segundoApellido}_${new Date().toLocaleDateString('es-ES')}.pdf`);
    };

    const convertirImagenABase64 = (ruta) => {
        return new Promise((resolve, reject) => {
            fetch(ruta)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
                .catch(reject);
        });
    };

    const handleBuscarClick = async () => {
        setPendiente(true);
        setListaDeRegistros([]);

        const data = {
            opcionSeleccionada: opcionSeleccionada,
            identificacion: identificacion,
            nombre: nombre,
            primerApellido: primerApellido,
            segundoApellido: segundoApellido,
            numContrato: numContrato,
            destino: destino
        };

        const resultadosObtenidos = await ObtenerListaEstadosDeCuenta(data);

        setListaDeRegistros(resultadosObtenidos);
        setPendiente(false);
        setMostrarGrid(true);

        if (resultadosObtenidos.length === 0) {
            setMensajeRespuesta({ indicador: 1, mensaje: "No se encontraron resultados." });
        } else {
            setMostrarBusqueda(!mostrarBusqueda);
            setMensajeRespuesta({});
        }
    };

    useEffect(() => {
        ObtenerIdUsuario();
    }, []);


    useEffect(() => {
        if (opcionSeleccionada === 1) {
            setDestino(''); 
        } else if (opcionSeleccionada === 2) {
            setNumContrato('');
        }
    }, [opcionSeleccionada]);


    const ObtenerIdUsuario = () => {
        let usuario = JSON.parse(sessionStorage.getItem("data_usuario"));
        if (usuario) {
            setIdUsuario(String(usuario.idUsuario));
        }
    };


    //evento de clic en el comentario
    const handleDatoClick = (datos) => {

        if (idUsuario) {
            setSelectedDato({
                ...datos,
                idUsuario: idUsuario,
                opcionSeleccionada: opcionSeleccionada,
            });
            setShowModal(true);
        }
    };


    const areInputsEmpty = () => {
        return (
            identificacion === '' &&
            nombre === '' &&
            primerApellido === '' &&
            segundoApellido === '' &&
            numContrato === null
        );
    };

    const ValidarSiFilaFueSeleccionada = (fila) => Object.entries(fila).length === 0 ? false : true;

    const onClickSeleccionarFila = (fila) => {
        const filaValida = ValidarSiFilaFueSeleccionada(fila);
        setBloquearBoton(!filaValida);

        if (filaValida) {
            setFilaSeleccionada(fila);

        } else {
            setFilaSeleccionada(null);
        }
    }




    const handleOpcionSeleccionadaChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setOpcionSeleccionada(value);
        setListaDeRegistros([]);
        setMostrarGrid(false);
    };

    const encabezadosComunes = [
        {
            id: 'identificacion', name: 'Identificacion', selector: (row) => (
                <div onClick={() => handleDatoClick(row)}>
                    {row.identificacion}
                </div>
            ), head: "Identificacion", sortable: true
        },
        {
            id: 'nombre', name: 'Nombre', selector: (row) => (
                <div onClick={() => handleDatoClick(row)}>
                    {row.nombre}
                </div>
            ), head: "Nombre", sortable: true
        },
        {
            id: 'primerApellido', name: 'Primer Apellido', selector: (row) => (
                <div onClick={() => handleDatoClick(row)}>
                    {row.primerApellido}
                </div>
            ), head: "Primer Apellido"
        },
        {
            id: 'segundoApellido', name: 'Segundo Apellido', selector: (row) => (
                <div onClick={() => handleDatoClick(row)}>
                    {row.segundoApellido}
                </div>
            ), head: "Segundo Apellido"
        },
    ];

    if (opcionSeleccionada === 1) {
        encabezadosComunes.push(
            {
                id: 'numContrato', name: 'Número de Contrato', selector: (row) => (
                    <div onClick={() => handleDatoClick(row)}>
                        {row.numContrato}
                    </div>
                ), head: "Número de Contrato"
            },
            {
                id: 'montoPagar', name: 'Monto a Pagar', selector: (row) => (
                    <div onClick={() => handleDatoClick(row)}>
                        {row.montoPagar}
                    </div>
                ), head: "Monto a Pagar"
            },
            {
                id: 'saldo', name: 'Saldo', selector: (row) => (
                    <div onClick={() => handleDatoClick(row)}>
                        {row.saldo}
                    </div>
                ), head: "Saldo"
            }
        );
    } else encabezadosComunes.push(
        {
        id: 'destino', name: 'Destino', selector: (row) => (
            <div onClick={() => handleDatoClick(row)}>
                {row.destino}
            </div>
        ), head: "Destino"
        },
        {
            id: 'montoPagar', name: 'Monto a Pagar', selector: (row) => (
                <div onClick={() => handleDatoClick(row)}>
                    {row.montoPagar}
                </div>
            ), head: "Monto a Pagar"
        },
        {
            id: 'saldo', name: 'Saldo', selector: (row) => (
                <div onClick={() => handleDatoClick(row)}>
                    {row.saldo}
                </div>
            ), head: "Saldo"
        }
    );

    const filterColumns = ['idPersona', 'nombre', 'primerApellido', 'segundoApellido', 'numContrato', 'destino'];

    return (
        <>
            <Card className="card-container " style={{ width: '100%' }}>
                <Card.Body>
                    <div className="container-fluid text-no">

                        <Row>
                            <Col md={8}>
                                <h1>Estados de cuenta</h1>
                            </Col>
                            <Col md={4} className="d-flex justify-content-end">
                                {listaDeRegistros.length > 0 && (
                                    <Button
                                        className="mr-1 mb-2 card-button"
                                        variant="Dark"
                                        style={{}}
                                        type="submit"
                                        size="sm"
                                        onClick={() => setMostrarBusqueda(!mostrarBusqueda)}
                                    >
                                        {mostrarBusqueda ? (
                                            <>
                                                <FontAwesomeIcon icon={faEyeSlash} className="mr-2" />
                                                Ocultar Buscar
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faEye} className="mr-2" />
                                                Mostrar Buscar
                                            </>
                                        )}
                                    </Button>

                                )}
                            </Col>
                        </Row>

                        <hr />


                        {mostrarBusqueda ? (
                            <div>
                                <div className="d-flex justify-content-end">
                                    <Button
                                        className="mr-1 mb-2 card-button"
                                        variant="primary"
                                        onClick={handleBuscarClick}
                                        style={{ marginBottom: '20px' }}
                                        disabled={areInputsEmpty()} >
                                        <FontAwesomeIcon icon={faSearch} className="mr-2" />
                                        Buscar
                                    </Button>
                                </div>


                                <Row>
                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <Form.Group className='mb-4'>
                                            <label htmlFor="buscar"><b>Buscar secundario:</b></label>
                                            <Form.Control
                                                as="select"
                                                value={opcionSeleccionada}
                                                onChange={handleOpcionSeleccionadaChange}
                                            >
                                                <option value="1">Club de Viajes</option>
                                                <option value="2">Paquetes</option>

                                            </Form.Control>
                                        </Form.Group>




                                    </Col>
                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <Form.Group className='mb-4'>
                                            <label htmlFor="identificacion"><b>Identificaci&oacute;n:</b></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Identificaci&oacute;n"
                                                value={identificacion}
                                                onChange={(e) => setIdentificacion(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <Form.Group className='mb-4'>
                                            <label htmlFor="nombre"><b>Nombre:</b></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Nombre"
                                                value={nombre}
                                                onChange={(e) => setNombre(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <Form.Group>
                                            <label htmlFor="primerApellido"><b>Primer Apellido:</b></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Primer apellido"
                                                value={primerApellido}
                                                onChange={(e) => setprimerApellido(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                        <Form.Group>
                                            <label htmlFor="segundoApellido"><b>Segundo Apellido:</b></label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Segundo apellido"
                                                value={segundoApellido}
                                                onChange={(e) => setsegundoApellido(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {opcionSeleccionada === 1 && (
                                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                            <Form.Group>
                                                <label htmlFor="numContrato"><b>N&uacute;mero de contrato</b></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Número de contrato"
                                                    value={numContrato}
                                                    onChange={(e) => setNumContrato(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                    {opcionSeleccionada === 2 && (
                                        <Col md={4} className="d-flex flex-column" style={{ padding: '0 30px' }}>
                                            <Form.Group>
                                                <label htmlFor="destino"><b>Destino</b></label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Destino"
                                                    value={destino}
                                                    onChange={(e) => setDestino(e.target.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}

                                </Row>
                            </div>
                        ) : null}

                        <div style={{ marginTop: '7vh' }}></div>


                        <div className="position-relative">

                            {mostrarGrid && !pendiente && (
                                <Grid
                                    gridHeading={encabezadosComunes}
                                    gridData={listaDeRegistros}
                                    selectableRows={false}
                                    pending={pendiente}
                                    setFilaSeleccionada={onClickSeleccionarFila}
                                    idBuscar="idClubViajePaquete" 
                                    rowModal={true}
                                    filterColumns={filterColumns}
                                />
                            )}
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <ModalExportarEstadoDeCuentaPDF
                show={showModal}
                handleClose={handleModalClose}
                datos={selectedDato}
                /*setComment={setSelectedDato}*/
                handleExport={handleExportPDF} 
            />
        </>
    );
}

export default EstadosDeCuenta;